import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { NftContext } from "../../NftContext/NftProvider";
// import { useNavigate } from 'react-router';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from 'axios';
import { AuthService } from '../../services/AuthService';

const Forgot = () => {
  // const navitage = useNavigate()
  const { forgotPassword } = useContext(NftContext);
  const { id, token } = useParams()
  const navigate = useNavigate();
  const [password, setpassword] = useState("");
  const authService = new AuthService();

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
      // username: Yup.string().required('New Password is required').email("Email is not valid"),
      password: Yup.string().required('New Password is required'),
    }),
    onSubmit: (value, { resetForm }) => {
      handleSubmit(value)
      toast.success('Password reset successfully!');
      // dispatch(getUserByEmail(value, navigate))
      // resetForm();
    },
  });

  const handleSubmit = async (value) => {
    try {
      const response = await axios.post(`${authService.baseUrl}/reset-password/${id}/${token}`, {
        password: value?.password,
      });

      if (response.status === 200) {

        navigate("/login");

      }
    } catch (err) {
      console.log("Error Setting New Password...", err);
    }

    

  };
  return (
    <>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-none d-sm-block">
              {/* <img src="./forgot-password-concept-flat.png" alt="password" className="img-fluid" /> */}
            </div>
            <div className="col-md-6 contents">
              <div className="row justify-content-center">
                <div className="col-md-12 col-lg-8">
                  <div className="mb-4">
                    <h3>Reset Password</h3>

                    <p className="text-primary"></p>
                  </div>
                  <form onSubmit={formik.handleSubmit}>
                  {/* <form onSubmit={handleSubmit}> */}
                    <div className="form-group">
                      <label htmlFor="password">New Password *</label>
                      <input type="password" className="form-control" placeholder='Type New Password Here...'
                        {...formik.getFieldProps('password')}
                        name="password"
                        required="" />
                      {formik.touched.password && formik.errors.password ? (
                        <div className="text-danger p-1">{formik.errors.password}</div>
                      ) : null}
                    </div>
                    <input type="submit" value="Submit" className="btn btn-block btn-primary custom-color-bttn mt-4" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Forgot