import axios from "axios";

export class AuthService {

    // baseUrl = "http://localhost:8080/api/products/";
    // baseUrl = "https://diversityproduction.pro/wp-json/buddyx/v2/";
    baseUrl ="https://admin.diversityproduction.pro/api/"
    // baseUrl ="http://localhost:5000/api/"

    // baseUrl = "https://diversityproduction.pro/wp-json/buddyx/v2/";

    login(payload){
        return axios.post(this.baseUrl+"sign-in", payload).then(res => res.data);
    }

    register(payload){
        return axios.post(this.baseUrl+"sign-up", payload).then(res => res.data);
    }

    forgotPassword(email){
        return axios.post(this.baseUrl+"forgot-password", email).then(res => res.data);
    }
    getCategories(){
        return axios.get(this.baseUrl+"categories").then(res => res.data);
    }
    
    getProfile(payload){
      console.log(payload);
        return axios.get(this.baseUrl+`nft-profile/${payload}`,{
            headers: {
              Authorization: localStorage.getItem('token')
            }
          }).then(res => res.data);
    }
    updateProfile(payload){
        return axios.put(this.baseUrl+"nft-profile", payload,{
            headers: {
              Authorization: localStorage.getItem('token')
            }
          }).then(res => res.data);
    }

    getsubscription(payload){
        return axios.get(this.baseUrl+"nft-subscription-plans").then(res => res.data);
    }
    
    updateSubscription(payload){
        return axios.post(this.baseUrl+"nft-users-subscription", payload,{
            headers: {
              Authorization: localStorage.getItem('token')
            }
          }).then(res => res.data);
    }

    getAllSubscriptions(payload){
      return axios.get(this.baseUrl+`plans/${payload}`);
  }

  deleteSubscription(payload){
    return axios.delete(this.baseUrl+`deleteplans/${payload}`);
}
  
    

}
