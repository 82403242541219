import React, { useContext, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { NftContext } from "../../NftContext/NftProvider";
import { useNavigate } from 'react-router';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { AuthService } from '../../services/AuthService'

const PaymentForm = (props) => {
    const navitage = useNavigate()
    const { currentUser, updateSubscription, subscriptionPlan, getsubscriptionPlan } = useContext(NftContext);

    const [priceId, setPriceId] = useState();
    const [planName, setPlanName] = useState();
    const [isPriceSelected, setIsPriceSelected] = useState(false)
    const [address, setAddress] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [userSubscriptionDetail, setUserSubscriptionDetail] = useState({});
    const [touchedFields, setTouchedFields] = useState({
        address: false,
        city: false,
        state: false,
        pinCode: false,
    });

    const {
        planName: plan,
        subscription_id,
        customer_id
    } = userSubscriptionDetail ?? {};

    


    const [subscriptionPrice, setSubscriptionPlanPrice] = useState({ key: '', price: 0, plan_type: '' });
    const [paymentMode, setPaymentMode] = useState(false)
    const [planId, setPlanId] = useState()
    
    const { handleSubmit, } = useForm({
        mode: "onBlur",
    });
    const elements = useElements()
    const stripe = useStripe()
    
    const authService = new AuthService();

    useEffect(() => {
        getsubscriptionPlan();

    }, [])

    useEffect(() => {
        setUserSubscriptionDetail(subscriptionPlan);

    }, [subscriptionPlan]);


    const handleSelectChange = (e, index) => {

        const selectedOption = e.target.options[e.target.selectedIndex];
        

        const priceId = selectedOption.getAttribute("data-priceid");
        const planName = selectedOption.getAttribute("data-planName");
        
        

        setPriceId(priceId);
        setPlanName(planName);
        setSubscriptionPlanPrice({ key: index, price: e.target.value, priceId });
        setIsPriceSelected(true);
    };

    const onPaymentSubmit = async (values, event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        const card = elements.getElement(CardElement);

        

        try {
            const { paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card,
            });

            if (paymentMethod) {
                const paymentMethodId = paymentMethod.id;
                const paymentData = {
                    user_id: currentUser.user._id,
                    userDetails: currentUser.user,
                    address: address,
                    pinCode: pinCode,
                    city: city,
                    state: state,
                    price_ids: priceId,
                    planName: planName,
                    paymentMethodId,
                    subscription_type: 'monthly',
                    cust_Id: customer_id,
                    subscription_id: subscription_id,
                };

                


                updateSubscription(paymentData, navitage)
                hideEditModal();
            } else {
                toast.error('Failed to create payment method.');
            }
        } catch (error) {
            console.error('Error creating payment method:', error);
            // toast.error('Error creating payment method.');
        }
    };

    const showEditModal = id => {
        console.log(id, '94');
        setPaymentMode(true);
        setPlanId(id);
        setAddress("");
        setPinCode("");
        setCity("");
        setState("");
    }
    const hideEditModal = async () => {
        setPaymentMode(false)

        setPlanId()
    }

    const isFormValid = () => {
        return address.trim() !== "" && city.trim() !== "" && state.trim() !== "" && pinCode.trim() !== "";
    };



    return (
        <>
            <div className="row justify-content-center">
                {props?.subscription && props?.subscription.map((item, index) => (
                    <div key={index} className="col-12 col-sm-8 col-md-6 col-lg-4">
                        <div className="single_price_plan wow fadeInUp" data-wow-delay="0.2s" >
                            <div className="title">
                                
                                <h4>{item.name}</h4>

                                <p>{item.description}</p>
                                <div className="line"></div>
                            </div>
                            {plan !== item.name ? <div className="price">
                                <h4>${index === subscriptionPrice.key ? subscriptionPrice.price : 0}</h4>
                                
                            </div> : <div className="price">
                                <h4>Current plan is Active </h4>
                                
                            </div>}
                            {plan !== item.name && <div className="description">
                                <div className="col">
                                    <select className="select2-selection form-select form-control" onChange={(e) => handleSelectChange(e, index)}>
                                        <option value="">--Select Plan--</option>
                                        {item?.price && item?.price?.map((res, index) => {
                                           
                                            let myStr = res.type?.split('_')[0];
                                            
                                            return <option data-planName={res.planName} data-priceid={res.priceId} key={index} value={res.amount}>{res.amount} ({myStr?.charAt(0).toUpperCase() + myStr?.slice(1)})</option>

                                        })}
                                    </select>
                                </div>
                            </div>}
                            {plan !== item.name ? <div className="button"><button type='button' className="btn btn-block btn-primary custom-color-bttn" onClick={() => {
                                if (isPriceSelected) {
                                    if (subscription_id) {
                                        toast.error("You already have an active plan. Please cancel your active plan to subscribe to a new plan!");
                                    } else {
                                        showEditModal(item.id);
                                    }
                                } else {
                                    toast.error("Select a plan before making payment");
                                }
                            }}>Purchase Now</button></div> : <>
                                <div className="button"><button type='button' className="btn btn-block btn-primary custom-color-bttn" style={{ marginBottom: '15px' }}

                                    disabled={true}>Purchased</button></div>
                                <div className="button"><button type='button' className="btn btn-block btn-primary custom-color-bttn" onClick={() => {
                                    authService.deleteSubscription(subscription_id).then((res) => { setUserSubscriptionDetail({}) });
                                    toast.success("Your plan has been cancelled successfully.");
                                }}>Cancel</button></div></>}
                        </div>
                    </div>
                ))}
            </div>
            <Modal show={paymentMode} onHide={hideEditModal}>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title">Make Payment</Modal.Title>
                    
                </Modal.Header>
                <Modal.Body>
                    <form className="form-auth-small" id="payment-form" onSubmit={handleSubmit(onPaymentSubmit)}>
                        <div className="col-md-12">
                            {paymentMode && (
                                <>
                                    <CardElement id="card-element" />
                                    <div className="form-group" style={{marginTop: '20px'}}>
                                        <label htmlFor="address">Address</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="address"
                                            placeholder="Enter your address"
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                            onBlur={() =>
                                                setTouchedFields({ ...touchedFields, address: true })
                                            }
                                        />
                                        {touchedFields.address && !address && (
                                            <span className="text-danger">Address is required</span>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="city">City</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="city"
                                            placeholder="Enter your city"
                                            value={city}
                                            onChange={(e) => setCity(e.target.value)}
                                            onBlur={() =>
                                                setTouchedFields({ ...touchedFields, city: true })
                                            }
                                        />
                                        {touchedFields.city && !city && (
                                            <span className="text-danger">City is required</span>
                                        )}
                                    </div>
                                    <label htmlFor="state"></label>
                                    <div className="form-group">
                                        <label htmlFor="state">State</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="state"
                                            placeholder="Enter your state"
                                            value={state}
                                            onChange={(e) => setState(e.target.value)}
                                            onBlur={() =>
                                                setTouchedFields({ ...touchedFields, state: true })
                                            }
                                        />
                                        {touchedFields.state && !state && (
                                            <span className="text-danger">State is required</span>
                                        )}
                                    </div>
                                    <label htmlFor="pincode"></label>
                                    <div className="form-group">
                                        <label htmlFor="pincode">Pin Code</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="pincode"
                                            placeholder="Enter your pincode"
                                            value={pinCode}
                                            onChange={(e) => setPinCode(e.target.value)}
                                            onBlur={() =>
                                                setTouchedFields({ ...touchedFields, pinCode: true })
                                            }
                                        />
                                       {touchedFields.pinCode && !pinCode && (
                                            <span className="text-danger">Pin Code is required</span>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                        <Modal.Footer className='mt-3'>
                            <button type="submit" className='btn btn-primary custom-color-bttn' disabled={!stripe || !elements || !isFormValid()}>Pay</button>
                            <button className='btn btn-outline-danger' onClick={hideEditModal}>Close</button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
};

export default PaymentForm;
